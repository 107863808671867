// https://auspost.com.au/content/dam/auspost_corp/media/documents/australia-post-addressing-standards-1999.pdf
export class AbbreviationExpander {
  static StreetType(abbreviation: string): string {
    switch (abbreviation.toLowerCase()) {
      case 'east':
        return 'East';
      case 'west':
        return 'West';
      case 'part':
        return 'Part';
      case 'triangle':
        return 'Triangle';
      case 'awlk':
        return 'Airwalk';
      case 'prec':
        return 'Precinct';
      case 'shun':
        return 'Shunt';
      case 'artl':
        return 'Arterial';
      case 'ba':
        return 'Banan';
      case 'rofw':
        return 'Right Of Way';
      case 'acre':
        return 'Acre';
      case 'wd':
        return 'Wood';
      case 'bull':
        return 'Bull';
      case 'vlla':
        return 'Villa';
      case 'tvse':
        return 'Traverse';
      case 'mart':
        return 'Mart';
      case 'accs':
        return 'Access';
      case 'ally':
        return 'Alley';
      case 'alwy':
        return 'Alleyway';
      case 'ambl':
        return 'Amble';
      case 'app':
        return 'Approach';
      case 'arc':
        return 'Arcade';
      case 'arty':
        return 'Artery';
      case 'av':
        return 'Avenue';
      case 'bank':
        return 'Bank';
      case 'bay':
        return 'Bay';
      case 'bch':
        return 'Beach';
      case 'bend':
        return 'Bend';
      case 'bwlk':
        return 'Boardwalk';
      case 'bvd':
        return 'Boulevard';
      case 'bvde':
        return 'Boulevarde';
      case 'bowl':
        return 'Bowl';
      case 'br':
        return 'Brace';
      case 'brae':
        return 'Brae';
      case 'bran':
        return 'Branch';
      case 'brk':
        return 'Break';
      case 'bret':
        return 'Brett';
      case 'bdge':
        return 'Bridge';
      case 'brdwlk':
        return 'Broadwalk';
      case 'bdwy':
        return 'Broadway';
      case 'brow':
        return 'Brow';
      case 'bypa':
        return 'Bypass';
      case 'bywy':
        return 'Byway';
      case 'cswy':
        return 'Causeway';
      case 'ctr':
        return 'Centre';
      case 'cnwy':
        return 'Centreway';
      case 'ch':
        return 'Chase';
      case 'cir':
        return 'Circle';
      case 'clt':
        return 'Circlet';
      case 'cct':
        return 'Circuit';
      case 'crcs':
        return 'Circus';
      case 'cl':
        return 'Close';
      case 'clr':
        return 'Cluster';
      case 'clde':
        return 'Colonnade';
      case 'cmmn':
        return 'Common';
      case 'cmmns':
        return 'Commons';
      case 'cncd':
        return 'Concord';
      case 'con':
        return 'Concourse';
      case 'cntn':
        return 'Connection';
      case 'cps':
        return 'Copse';
      case 'cnr':
        return 'Corner';
      case 'cso':
        return 'Corso';
      case 'crse':
        return 'Course';
      case 'ct':
        return 'Court';
      case 'ctyd':
        return 'Courtyard';
      case 'cove':
        return 'Cove';
      case 'cr':
        return 'Crescent';
      case 'crst':
        return 'Crest';
      case 'crf':
        return 'Crief';
      case 'crk':
        return 'Crook';
      case 'crss':
        return 'Cross';
      case 'crsg':
        return 'Crossing';
      case 'cuwy':
        return 'Cruiseway';
      case 'csac':
        return 'Cul-De-Sac';
      case 'cut':
        return 'Cut';
      case 'cutt':
        return 'Cutting';
      case 'dale':
        return 'Dale';
      case 'dell':
        return 'Dell';
      case 'dene':
        return 'Dene';
      case 'de':
        return 'Deviation';
      case 'dip':
        return 'Dip';
      case 'dstr':
        return 'Distributor';
      case 'div':
        return 'Divide';
      case 'dock':
        return 'Dock';
      case 'dom':
        return 'Domain';
      case 'down':
        return 'Down';
      case 'dwns':
        return 'Downs';
      case 'dr':
        return 'Drive';
      case 'dvwy':
        return 'Driveway';
      case 'esmt':
        return 'Easement';
      case 'edge':
        return 'Edge';
      case 'elb':
        return 'Elbow';
      case 'end':
        return 'End';
      case 'ent':
        return 'Entrance';
      case 'esp':
        return 'Esplanade';
      case 'est':
        return 'Estate';
      case 'exp':
        return 'Expressway';
      case 'extn':
        return 'Extension';
      case 'fawy':
        return 'Fairway';
      case 'fbrk':
        return 'Firebreak';
      case 'flne':
        return 'Fireline';
      case 'ftrk':
        return 'Firetrack';
      case 'fitr':
        return 'Firetrail';
      case 'flat':
        return 'Flat';
      case 'folw':
        return 'Follow';
      case 'ftwy':
        return 'Footway';
      case 'ford':
        return 'Ford';
      case 'fshr':
        return 'Foreshore';
      case 'fork':
        return 'Fork';
      case 'form':
        return 'Formation';
      case 'fwy':
        return 'Freeway';
      case 'frnt':
        return 'Front';
      case 'frtg':
        return 'Frontage';
      case 'gap':
        return 'Gap';
      case 'gdn':
        return 'Garden';
      case 'gdns':
        return 'Gardens';
      case 'gte':
        return 'Gate';
      case 'gwy':
        return 'Gateway';
      case 'glde':
        return 'Glade';
      case 'glen':
        return 'Glen';
      case 'gra':
        return 'Grange';
      case 'grn':
        return 'Green';
      case 'gr':
        return 'Grove';
      case 'gly':
        return 'Gully';
      case 'hrbr':
        return 'Harbour';
      case 'hvn':
        return 'Haven';
      case 'hth':
        return 'Heath';
      case 'hts':
        return 'Heights';
      case 'hird':
        return 'Highroad';
      case 'hwy':
        return 'Highway';
      case 'hill':
        return 'Hill';
      case 'hllw':
        return 'Hollow';
      case 'hub':
        return 'Hub';
      case 'inlt':
        return 'Inlet';
      case 'intg':
        return 'Interchange';
      case 'id':
        return 'Island';
      case 'jnc':
        return 'Junction';
      case 'key':
        return 'Key';
      case 'keys':
        return 'Keys';
      case 'knol':
        return 'Knoll';
      case 'ladr':
        return 'Ladder';
      case 'ldg':
        return 'Landing';
      case 'lane':
        return 'Lane';
      case 'lnwy':
        return 'Laneway';
      case 'ledr':
        return 'Leader';
      case 'line':
        return 'Line';
      case 'link':
        return 'Link';
      case 'lkt':
        return 'Lookout';
      case 'loop':
        return 'Loop';
      case 'lynn':
        return 'Lynne';
      case 'mall':
        return 'Mall';
      case 'manr':
        return 'Manor';
      case 'mead':
        return 'Mead';
      case 'mndr':
        return 'Meander';
      case 'mew':
        return 'Mew';
      case 'mews':
        return 'Mews';
      case 'mile':
        return 'Mile';
      case 'mtwy':
        return 'Motorway';
      case 'nook':
        return 'Nook';
      case 'nth':
        return 'North';
      case 'null':
        return 'Null';
      case 'otlt':
        return 'Outlet';
      case 'otlk':
        return 'Outlook';
      case 'oval':
        return 'Oval';
      case 'plms':
        return 'Palms';
      case 'pde':
        return 'Parade';
      case 'prds':
        return 'Paradise';
      case 'park':
        return 'Park';
      case 'pwy':
        return 'Parkway';
      case 'pass':
        return 'Pass';
      case 'psge':
        return 'Passage';
      case 'path':
        return 'Path';
      case 'pway':
        return 'Pathway';
      case 'psla':
        return 'Peninsula';
      case 'piaz':
        return 'Piazza';
      case 'pl':
        return 'Place';
      case 'plza':
        return 'Plaza';
      case 'pkt':
        return 'Pocket';
      case 'pnt':
        return 'Point';
      case 'port':
        return 'Port';
      case 'prom':
        return 'Promenade';
      case 'prst':
        return 'Pursuit';
      case 'quad':
        return 'Quad';
      case 'qdrt':
        return 'Quadrant';
      case 'qy':
        return 'Quay';
      case 'qys':
        return 'Quays';
      case 'rmbl':
        return 'Ramble';
      case 'ramp':
        return 'Ramp';
      case 'rnge':
        return 'Range';
      case 'rch':
        return 'Reach';
      case 'reef':
        return 'Reef';
      case 'res':
        return 'Reserve';
      case 'rest':
        return 'Rest';
      case 'rtt':
        return 'Retreat';
      case 'rtn':
        return 'Return';
      case 'ride':
        return 'Ride';
      case 'rdge':
        return 'Ridge';
      case 'ring':
        return 'Ring';
      case 'rise':
        return 'Rise';
      case 'rsng':
        return 'Rising';
      case 'rvr':
        return 'River';
      case 'rd':
        return 'Road';
      case 'rds':
        return 'Roads';
      case 'rdwy':
        return 'Roadway';
      case 'rty':
        return 'Rotary';
      case 'rnd':
        return 'Round';
      case 'rte':
        return 'Route';
      case 'row':
        return 'Row';
      case 'rowe':
        return 'Rowe';
      case 'rue':
        return 'Rue';
      case 'run':
        return 'Run';
      case 'svwy':
        return 'Serviceway';
      case 'skln':
        return 'Skyline';
      case 'slpe':
        return 'Slope';
      case 'sth':
        return 'South';
      case 'spur':
        return 'Spur';
      case 'sq':
        return 'Square';
      case 'stps':
        return 'Steps';
      case 'strt':
        return 'Straight';
      case 'stai':
        return 'Strait';
      case 'stra':
        return 'Strand';
      case 'st':
        return 'Street';
      case 'strp':
        return 'Strip';
      case 'sbwy':
        return 'Subway';
      case 'tarn':
        return 'Tarn';
      case 'tce':
        return 'Terrace';
      case 'thfr':
        return 'Thoroughfare';
      case 'thru':
        return 'Throughway';
      case 'tlwy':
        return 'Tollway';
      case 'top':
        return 'Top';
      case 'tor':
        return 'Tor';
      case 'trk':
        return 'Track';
      case 'trl':
        return 'Trail';
      case 'tmwy':
        return 'Tramway';
      case 'tkwy':
        return 'Trunkway';
      case 'tunl':
        return 'Tunnel';
      case 'turn':
        return 'Turn';
      case 'upas':
        return 'Underpass';
      case 'vale':
        return 'Vale';
      case 'vlly':
        return 'Valley';
      case 'verge':
        return 'Verge';
      case 'viad':
        return 'Viaduct';
      case 'view':
        return 'View';
      case 'vws':
        return 'Views';
      case 'vlge':
        return 'Village';
      case 'vlls':
        return 'Villas';
      case 'vsta':
        return 'Vista';
      case 'vue':
        return 'Vue';
      case 'wade':
        return 'Wade';
      case 'walk':
        return 'Walk';
      case 'wkwy':
        return 'Walkway';
      case 'wtrs':
        return 'Waters';
      case 'wtwy':
        return 'Waterway';
      case 'way':
        return 'Way';
      case 'whrf':
        return 'Wharf';
      case 'wds':
        return 'Woods';
      case 'wynd':
        return 'Wynd';
      case 'yard':
        return 'Yard';
      case 'twist':
        return 'Twist';
      case 'dash':
        return 'Dash';
      case 'hills':
        return 'Hills';
      case 'lead':
        return 'Lead';
      case 'bswy':
        return 'Busway';
      case 'flts':
        return 'Flats';
      case 'mz':
        return 'Maze';
      case 'hike':
        return 'Hike';
      case 'conr':
        return 'Connector';
      case 'bdy':
        return 'Boundary';
      case 'crvr':
        return 'Crossover';
      case 'lnkway':
        return 'Linkway';
      case 'annex':
        return 'Annex';
      case 'bidi':
        return 'Bidi';
      case 'yards':
        return 'Yards';
      case 'nest':
        return 'Nest';
      case 'sdng':
        return 'Siding';
      case 'prch':
        return 'Perch';
    }

    return abbreviation;
  }
  static SubUnitType(abbreviation: string): string {
    switch (abbreviation) {
      case 'APT':
        return 'Apartment';
      case 'SHOP':
        return 'Shop';
      case 'FY':
        return 'Factory';
      case 'SITE':
        return 'Site';
      case 'F':
        return 'Flat';
      case 'SL':
        return 'Stall';
      case 'MB':
        return 'Marine berth';
      case 'SE':
        return 'Suite';
      case 'OFF':
        return 'Office';
      case 'U':
        return 'Unit';
      case 'RM':
        return 'Room';
      case 'VLLA':
        return 'Villa';
      case 'SHED':
        return 'Shed';
      case 'WE':
        return 'Warehouse';
    }

    return abbreviation;
  }

  static FloorType(abbreviation: string): string {
    switch (abbreviation) {
      case 'B':
        return 'Basement';
      case 'FL':
        return 'Floor';
      case 'G':
        return 'Ground Floor';
      case 'L':
        return 'Level';
      case 'LG':
        return 'Lower Ground Floor';
      case 'M':
        return 'Mezzanine';
      case 'UG':
        return 'Upper Ground Floor';
    }

    return abbreviation;
  }

  static State(abbreviation: string): string {
    switch (abbreviation) {
      case 'ACT':
        return 'Australian Capital Territory';
      case 'NSW':
        return 'New South Wales';
      case 'NT':
        return 'Northern Territory';
      case 'QLD':
        return 'Queensland';
      case 'SA':
        return 'South Australia';
      case 'TAS':
        return 'Tasmania';
      case 'VIC':
        return 'Victoria';
      case 'WA':
        return 'Western Australia';
    }

    return abbreviation;
  }

  static PostalDeliveryType(abbreviation: string): string {
    switch (abbreviation) {
      case 'CARE PO':
        return 'Care of Post Office';
      case 'CMA':
        return 'Community Mail Agent.';
      case 'CMB':
        return 'Community Mail Bag';
      case 'CPA':
        return 'Community Postal Agent';
      case 'GPO BOX':
        return 'General Post Office Box';
      case 'LOCKED BAG':
        return 'Locked Bag';
      case 'MS':
        return 'Mail Service';
      case 'PO BOX':
        return 'Post Office Box';
      case 'RSD':
        return 'Roadside Delivery';
      case 'RMB':
        return 'Roadside Mail Box/Bag';
      case 'RMS':
        return 'Roadside Mail Service';
      case 'PRIVATE BAG':
        return 'Private Bag';
    }

    return abbreviation;
  }

}
