import { bind, Binder } from 'immer-yjs';
import * as Y from 'yjs';
import { JsonFormattedLogger } from '../logging';
import { Snapshot } from '../util/form/prefill';

export function isEmptyUpdate(update: Uint8Array) {
  return update.length === 0
    || (update.length === 2 && update[0] === 0 && update[1] === 0);
}

export interface Migration<T> {
  name: string;
  condition: (t: T) => boolean;
  fn: (t: T) => void;
}

export function applyMigrations<T extends Snapshot>(typeName: string, binder: Binder<T>, migrations: Array<Migration<T>>): boolean {
  if (!migrations.length) {
    return false;
  }
  let changed = false;

  for (const migration of migrations) {
    if (!migration.condition(binder.get())) {
      continue;
    }
    console.log(`Applying migration to type [${typeName}]: [${migration.name}]`);
    binder.update(migration.fn);
    changed = true;
  }

  return changed;
}

export class SkipMigrationError extends Error { }
class RollbackAllMigrationsError extends Error {
  constructor(public originalError: unknown) { super(); }
}

export interface MigrationV2<T> {
  /**
   * Friendly name for the migration
   */
  name: string;
  /**
   * Defines a migration to apply to the ydoc.
   * If the entire suite of migrations should be rolled back, throw an Error.
   * If this specific migration should be rolled back, return false or throw a SkipMigrationError.
   *
   * @returns false if this specific migration should be rolled back and skipped, whereas true/void indicate success.
   */
  fn: (t: T ) => void | boolean;
}

export interface MigrationV2_1<T> extends MigrationV2<T> {
  docKey?: string // Override the top level document key
}

function applyMigrationV2<T extends Snapshot>(log: JsonFormattedLogger, binder: Binder<T>, migration: MigrationV2<T> | MigrationV2_1<T>, undoManager: Y.UndoManager) {
  try {
    if ('docKey' in migration) {
      log.info(`Applying migration [${migration.name}] on key [${migration.docKey}]...`);
    } else {
      log.info(`Applying migration [${migration.name}]...`);
    }
    binder.update(state => {
      try {
        const result = migration.fn(state);
        if (result === false) {
          throw new SkipMigrationError();
        }
      } catch (err: unknown) {
        if (err instanceof SkipMigrationError) {
          throw err;
        }
        throw new RollbackAllMigrationsError(err);
      }
    });
    return true;
  } catch (err: unknown) {
    if (err instanceof SkipMigrationError) {
      log.info(`Rollback migration [${migration.name}]`, { ...err && { error: err } });
      while (undoManager.canUndo()) {
        undoManager.undo();
      }
      return false;
    }
    throw err;
  } finally {
    undoManager.destroy();
  }
}

/**
 * Note in the case of a full rollback:
 *  Yjs is a bit weird. Even though the undo manager undid all the changes,
 *  there still have technically been updates to the underlying y doc.
 *  Typically if the caller isn't doing anything further with the doc, they can ignore this note.
 *  However, if they are doing multiple actions on the doc and then distributing the update(s),
 *  it is recommended that they first take state vector snapshot prior to doing the work,
 *  and use that to generate a single update for distribution.
 */
export function applyMigrationsV2<T extends Snapshot>(opts: {
  typeName: string,
  doc: Y.Doc,
  docKey: string | undefined,
  migrations: Array<MigrationV2<T>>
}): Uint8Array | undefined {
  const log = new JsonFormattedLogger('applyMigrationsV2').getNested(opts.typeName);
  const preVector = Y.encodeStateVector(opts.doc);
  const root = opts.doc.getMap(opts.docKey);
  const everythingUndoManager = new Y.UndoManager(root);
  try {
    let changed = false;
    Y.transact(opts.doc, () => {
      for (const migration of opts.migrations) {
        const binder = bind<T>(root);
        try {
          changed = applyMigrationV2(log, binder, migration, new Y.UndoManager(root)) || changed;
        } finally {
          binder.unbind();
        }
      }
    });

    return changed
      ? Y.encodeStateAsUpdate(opts.doc, preVector)
      : undefined;
  } catch (err: unknown) {
    log.info('Rollback all migrations', { error: err });
    while (everythingUndoManager.canUndo()) {
      everythingUndoManager.undo();
    }

    throw err instanceof RollbackAllMigrationsError
      ? err.originalError
      : err;
  } finally {
    everythingUndoManager.destroy();
  }
}

export function applyMigrationsV2_1<T extends Snapshot>(opts: {
  typeName: string,
  doc: Y.Doc,
  docKey: string,
  migrations: Array<MigrationV2_1<T>|{docKey: string, name: string, clear: true}>
}): Uint8Array | undefined {
  const log = new JsonFormattedLogger('applyMigrationsV2_1').getNested(opts.typeName);
  const preVector = Y.encodeStateVector(opts.doc);
  const root = opts.doc.getMap(opts.docKey);
  const everythingUndoManager = new Y.UndoManager(root);

  try {
    let changed = false;
    Y.transact(opts.doc, () => {
      for (const migration of opts.migrations) {
        const effectiveKey = migration.docKey ?? opts.docKey;
        const effectiveRoot = opts.doc.getMap(effectiveKey);
        const binder = bind<T>(effectiveRoot);

        try {
          if ('clear' in migration) {
            log.info(`Applying migration clear: [${migration.name}]...`);
            effectiveRoot.clear();
            changed = true;
          } else {
            changed = applyMigrationV2(log, binder, migration, new Y.UndoManager(effectiveRoot)) || changed;
          }

        } finally {
          binder.unbind();
        }
      }
    });

    return changed
      ? Y.encodeStateAsUpdate(opts.doc, preVector)
      : undefined;
  } catch (err: unknown) {
    log.info('Rollback all migrations', { error: err });
    while (everythingUndoManager.canUndo()) {
      everythingUndoManager.undo();
    }

    throw err instanceof RollbackAllMigrationsError
      ? err.originalError
      : err;
  } finally {
    everythingUndoManager.destroy();
  }
}
