import { PartyCategory } from '../../yjs-schema/property/form';
export type ExtractedField = {
  id: string;
  positions: {
    x: number;
    y: number;
    width: number;
    height: number;
    /**
     * index
     */
    page: number;
  }[];
  _debug?: Record<string, any>;
}

export type PageDimension = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export interface ImagePlacement {
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum ImagePartyType {
  Unknown = 0,
  Vendor = 1,
  Purchaser = 2,
  Agent = 3,
  Landlord = 4,
  Tenant = 5,
  Manager = 6,
  Accountant = 7,
  Applicant = 8,
  Assignee = 9,
  Assignor = 10,
  Auctioneer = 11,
  Auditor = 12,
  Auth = 13,
  Bidder = 14,
  Clerk = 15,
  Client = 16,
  CoOwner = 17,
  Consumer = 18,
  Contractor = 19,
  Council = 20,
  Customer = 21,
  Declarant = 22,
  Deligate = 23,
  Guarantor = 24,
  InTenant = 25,
  OutTenent = 26,
  Issuer = 27,
  Lawyer = 28,
  Legal = 29,
  Lessee = 30,
  Lessor = 31,
  Mortgagee = 32,
  Name = 33,
  Offeror = 34,
  Officer = 35,
  Owner = 36,
  Principal = 37,
  Professional = 38,
  Proxy = 39,
  Seller = 40,
  Signature = 41,
  Witness = 42
}

export const ImagePartyTypeStr = {
  [ImagePartyType.Unknown]: 'unknown',
  [ImagePartyType.Vendor]: 'vendor',
  [ImagePartyType.Purchaser]: 'purchaser',
  [ImagePartyType.Agent]: 'agent',
  [ImagePartyType.Landlord]: 'landlord',
  [ImagePartyType.Tenant]: 'tenant',
  [ImagePartyType.Manager]: 'manager',
  [ImagePartyType.Accountant]: 'accountant',
  [ImagePartyType.Applicant]: 'applicant',
  [ImagePartyType.Assignee]: 'assignee',
  [ImagePartyType.Assignor]: 'assignor',
  [ImagePartyType.Auctioneer]: 'auctioneer',
  [ImagePartyType.Auditor]: 'auditor',
  [ImagePartyType.Auth]: 'auth',
  [ImagePartyType.Bidder]: 'bidder',
  [ImagePartyType.Clerk]: 'clerk',
  [ImagePartyType.Client]: 'client',
  [ImagePartyType.CoOwner]: 'coowner',
  [ImagePartyType.Consumer]: 'consumer',
  [ImagePartyType.Contractor]: 'contractor',
  [ImagePartyType.Council]: 'council',
  [ImagePartyType.Customer]: 'customer',
  [ImagePartyType.Declarant]: 'declarant',
  [ImagePartyType.Deligate]: 'deligate',
  [ImagePartyType.Guarantor]: 'guarantor',
  [ImagePartyType.InTenant]: 'intenant',
  [ImagePartyType.OutTenent]: 'outTenent',
  [ImagePartyType.Issuer]: 'issuer',
  [ImagePartyType.Lawyer]: 'lawyer',
  [ImagePartyType.Legal]: 'legal',
  [ImagePartyType.Lessee]: 'lessee',
  [ImagePartyType.Lessor]: 'lessor',
  [ImagePartyType.Mortgagee]: 'mortgagee',
  [ImagePartyType.Name]: 'name',
  [ImagePartyType.Offeror]: 'offeror',
  [ImagePartyType.Officer]: 'officer',
  [ImagePartyType.Owner]: 'owner',
  [ImagePartyType.Principal]: 'principal',
  [ImagePartyType.Professional]: 'Professional',
  [ImagePartyType.Proxy]: 'proxy',
  [ImagePartyType.Seller]: 'seller',
  [ImagePartyType.Signature]: 'signature',
  [ImagePartyType.Witness]: 'witness'
};

export interface ImageEncodedSigningParty {
  partyType: PartyCategory,
  number: number
  multipleOfType?: boolean;
  fieldType: 'initial' | 'signature';
}

export interface ImagePagePlacement extends ImagePlacement {
  pageIndex: number;
  party?: ImageEncodedSigningParty;
}

export interface StaticTextPlacement {
  pageIndex: number,
  text: string,
  top: number,
  left: number,
  bottom: number,
  right: number,
  size: number
}
