import { PathSegments, PathType } from '@property-folders/contract/yjs-schema/model';
import { FormCode, FormCodeUnion } from '@property-folders/contract';
import { FormCardRenderOpts, FormStateRequirementDescriptor, FormStateRequirementMessage, PartyCategory } from './form';
import { ServeField } from '../../signing/pdf-form-field';
import { CustomFieldMetaGroup } from '@property-folders/contract/property/meta';

export enum FormState {
  SIGNED = 'SIGNED',
  DRAFT = 'DRAFT',
  AWAITING_SIGN = 'PENDING',
  EXPIRED = 'EXPIRED',
  NOT_ADDED = 'NOTCREATED',
  DECLINED = 'DECLINED',
  CONFIGURING = 'CONFIGURING',
  ORDER_ORDERING = 'ORDER_ORDERING',
  ORDER_PREPARING = 'ORDER_PREPARING',
  ORDER_RETURNED = 'ORDER_RETURNED',
  ORDER_CANCELLED = 'ORDER_CANCELLED'
}

// Used to calculate expiries and other things. Duration in particular, 90 is super standard.
type FormValidityDocumentExpiryPaths = {
  duration?: PathType
  startDate?: PathType
  startEnable?: PathType
};

// We will see if this is actually desired later
type FormValidityDocumentExpiryPathsDefaults = {
  [Property in keyof FormValidityDocumentExpiryPaths]: any
};

export type FillContiguousSpaceSigningPlacementStrategy = {
  // group together party signature locations
  // use the space between the top of the highest, and the bottom of the lowest
  // expand the space sideways to fill the left/right page margins
  // optionally, expand downwards beyond the bottom of the lowest
  type: 'fill-contiguous-space',
  marginLeft: number,
  marginRight: number,
  expandDown?: number,
  expandUp?: number,
  // drawn along the left, starting at the marginLeft (pushes in the signing space to accomodate)
  signedText?: {
    size: number,
    width: number
  },
  timestampText?: {
    x: number;
    y: number;
  }
};

export type ExpandSigningPlacementStrategy = {
  type: 'expand',
  expandDown?: number,
  expandUp?: number,
  expandLeft?: number,
  expandRight?: number,
  signedText?: {
    size: number,
    width: number
  },
  timestampText?: {
    x: number;
    y: number;
  }
};

export type SigningPlacementStrategy = FillContiguousSpaceSigningPlacementStrategy | ExpandSigningPlacementStrategy;

export type ServeFieldInjection = {
  field: ServeField;
  location: {
    /**
     * index
     */
    page: number;
    top: number;
    left: number;
    width: number;
    height: number;
  };
  font: {
    size: number;
  }
};

export type FormDescriptorParty = {
  type: PartyCategory,
  mode?: 'primary-only'
};

export type FormDescriptorRecord = {
  label: string,
  /**
   * Title that will be at the top of the Wizard page and PDF heading, sans any suffix such as - Residential.
   * Created because there were some forms that were manually specifying titles
   */
  printTitle?: string,
  wizardTitle?: string,
  shortLabel?: string,
  description: string,
  /**
   * Can be ordered for completion by a 3rd party
   */
  orderable?: boolean,
  /**
   * Group forms/children together by their family
   */
  formFamily: FormCode,
  /**
   * There can only be one of this form type
   */
  primary?: boolean,
  /**
   * Rules for display on the main (bucket) list
   * if not set, don't show.
   * todo-future: split into separate rules-
   *              * should it show on the main list
   *              * are creation conditions met?
   */
  suggestion?: FormStateRequirementDescriptor
  renderOpts?: FormCardRenderOpts
  signingRequirements?: FormStateRequirementDescriptor
  signingRequirementsMessage?: FormStateRequirementMessage,
  /**
   * false (default): Parties may sign different counterparts
   * true: Parties must sign the same document, with the implications:
   * - No mixing signing modes (digital/wet), as this would create digital/wet counterparts.
   * - When uploading a copy of the signed wet document, all parties must be represented on that copy.
   *   i.e. All checkboxes checked, all dates specified.
   */
  disableCounterpartSigning?: boolean,
  usesPartyEmail?: boolean,
  usesPartyPhone?: boolean,
  parties?: FormDescriptorParty[],
  authRepParties?: FormDescriptorParty[],
  variationClassificationNoun?: string,
  navigateTo?: string,
  /**
   * i.e. is legacy
   */
  subscription?: {
    signing?: {
      placementStrategies?: {[key in PartyCategory | 'default' | 'default_initials']?: SigningPlacementStrategy | undefined},
      serveFields?: ServeFieldInjection[],
      useGroups?: boolean
    }
  },
  debug?: boolean,
  documentExpiryPaths: FormValidityDocumentExpiryPaths,
  documentExpiryPathsDefaults: FormValidityDocumentExpiryPathsDefaults,
  isVariation?: boolean,
  /**If a form marked with isTermination is signed, then the whole lineage shall be considedred
   * done.
   *
   */
  isTermination?: boolean,
  externalTerminationCode?: FormCodeUnion
  serveToPurchaser?: boolean,
  wizardOpts?: {
    noSigning?: boolean // Wizard shall not engage the signing process and not show the button
    pdfOnly?: boolean
  },
  recommendVariation?: boolean
  commonWatchPaths?: VariationSectionWatches; // Paths to watch that other forms may modify that would affect this form family (eg not clauses or annexures)
  isCustomisable?: boolean;
  disableWetSigning?: boolean;
  /**
   * If the users are uploading an already-signed pdf/file for this type of document,
   * then signing can be skipped
   */
  allowEmptySigning?: boolean;
  customiseFieldTypes?: Record<CustomFieldMetaGroup, 'full' | 'restricted' | 'hidden'>;
  /**
   * FormCode for the uploaded-file equivalent of this document.
   * e.g. form1 may have a form1upload equivalent
   */
  uploadVersion?: FormCodeUnion;
  useLatestOnPropertyCard?: boolean;
  archiveSiblingTypesOnCreate?: FormCodeUnion[];
};

export interface VariationSectionWatches {[sectionKey:string]: PathSegments[]}
