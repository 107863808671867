import { LinkBuilder } from '../util/LinkBuilder';
import { WrappedFetch } from '../client-api/wrappedFetch';
import { FormItem } from '@property-folders/contract/rest/forms';
import {
  FormSigningState,
  formSigningStateToString
} from '@property-folders/contract';
import { BaseAjaxResponse } from '../client-api/legacyApi';

export interface ListSubscriptionFormsResponse {
  results: FormItem[]
}

export type LogoType = 'Logo' | 'PurchaserRegistrationImage';

export class AjaxPhp {
  static async login(email: string, password: string) {
    const fdata = new FormData();
    fdata.append('email', email.toLowerCase());
    fdata.append('password', password);
    try {
      const response = await fetch(`${LinkBuilder.ajax}?action=login`, {
        method: 'post',
        body: fdata
      });
      const body = await response.json();

      if (!body?.success) {
        console.log('login failure', body);
        return false;
      }

      return true;
    } catch (err: unknown) {
      console.error('login failure', err);
      return false;
    }
  }

  static async loginAs(userId: number) {
    const response = await fetch(`${LinkBuilder.ajax}?action=loginas&UserID=${userId}`, {
      method: 'post'
    });

    return response.status === 200;
  }

  static async extendSession() {
    try {
      const response = await fetch(`${LinkBuilder.ajax}?action=extendsession`, {
        method: 'post'
      });
      const body = await response.json();

      if (!body?.success) {
        console.log('session extension failure', body);
        return false;
      }
      return true;
    } catch (err: unknown) {
      console.error('session extension failure', err);
      return false;
    }
  }

  static listSubscriptionForms(entityId: string | number, withClauses = false) {
    entityId = String(entityId);

    return WrappedFetch.json<ListSubscriptionFormsResponse>(`${LinkBuilder.legacyApi(`/form/entity/${entityId}?withClauses=${withClauses}`)}`);
  }

  static async loginWithCode(code: string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=login&code=${code}`);
  }

  static async archiveDocument(id: number | string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=archivedocument&DocumentID=${id}`);
  }

  static async unarchiveDocument(id: number | string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=unarchivedocument&DocumentID=${id}`);
  }

  static async generatePdf(id: number | string, download?: boolean) {
    return WrappedFetch.json<{ success: boolean; link: string; name: string }>(
      LinkBuilder.ajaxRequest({
        action: 'generatepdf',
        DocumentID: id,
        Download: !!download
      })
    );
  }

  static generatePdfUrl(id: number | string) {
    return LinkBuilder.reaformsFromRoot(`/tools/GeneratePDF.php?DocID=${id}`, true);
  }

  static generateAuditPdfUrl(id: number | string) {
    return LinkBuilder.ajaxRequest({ action: 'generateauditpdf', DocumentID: id });
  }

  static async renameDocument({ id, newName }: { id: number | string, newName: string }) {
    return WrappedFetch.json<{ success: boolean; }>(LinkBuilder.ajaxRequest({
      action: 'renamedocument',
      DocumentID: id,
      renamename: newName
    }));
  }

  static async assignAgentToDocument({ documentId, agentId }: {
    documentId: number | string,
    agentId: number | string
  }) {
    return WrappedFetch.json<{ success: boolean }>(LinkBuilder.ajaxRequest({
      action: 'assignagent',
      DocumentID: documentId,
      assign_available: agentId
    }));
  }

  static async uploadLogo({
    entityUuid,
    type,
    data
  }: {
    entityUuid: string,
    type: LogoType,
    data: Blob
  }) {
    const fdata = new FormData();
    fdata.append('LogoUpload', data);
    return WrappedFetch.json<{ success: boolean, url: string, urlLegacy: string }>(LinkBuilder.ajaxRequest({
      action: 'uploadlogo',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post',
      body: fdata
    });
  }

  static async removeLogo({
    entityUuid,
    type
  }: {
    entityUuid: string,
    type: LogoType
  }) {
    return WrappedFetch.json<{ success: boolean }>(LinkBuilder.ajaxRequest({
      action: 'removelogo',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post'
    });
  }

  static async getLogoUrl({
    entityUuid,
    type
  }: {
    entityUuid: string,
    type: LogoType
  }) {
    return WrappedFetch.json<{ success: boolean, url: string }>(LinkBuilder.ajaxRequest({
      action: 'getlogourl',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post'
    });
  }

  static async getEntitySettingsInfo({
    entityUuid,
    signal
  }: {
    entityUuid: string,
    signal: AbortSignal
  }) {
    return WrappedFetch.json<GetEntitySettingsInfoResponse>(LinkBuilder.ajaxRequest({
      action: 'getentitysettingsinfo',
      EntityUUID: entityUuid
    }), {
      method: 'post',
      signal
    });
  }

  static async adminSaveAgent(props: {
    agentId: number,
    formData: FormData
  }) {
    return WrappedFetch.json<BaseAjaxResponse & { message?: string }>(LinkBuilder.ajaxRequest({
      action: 'admin_saveagent',
      AgentID: props.agentId
    }), {
      method: 'POST',
      body: props.formData
    });
  }

  static async getEntitiesUserTeams(props: {
    entityIds: number[],
    agentId: number
  }) {
    const fd = new FormData();
    for (const id of props.entityIds) {
      fd.append('EntityIDs[]', id.toString());
    }
    fd.append('AgentID', props.agentId.toString());
    return WrappedFetch.json<BaseAjaxResponse & { items: { value: string, label: string }[]}>(LinkBuilder.ajaxRequest({ action: 'getentitiesuserteams' }), {
      method: 'POST',
      body: fd
    });
  }

  static async getDocumentMeta({
    documentId,
    signal
  }: {
    documentId: string | number,
    signal: AbortSignal
  }) {
    return WrappedFetch.json<GetDocumentMetaResponse>(LinkBuilder.legacyApi(`/document/${documentId}`), {
      method: 'get',
      signal
    }).then(r => ({
      ...r,
      signatures: r?.signatures.map(s => s.trim()) ?? []
    }) as GetDocumentMetaResponse);
  }

  static async setDocumentPropertyFolder({
    documentId,
    signal,
    propertyFolderId,
    propertyFormId
  }: {
    documentId: string | number,
    propertyFolderId: string;
    propertyFormId: string;
    signal: AbortSignal
  }) {
    return WrappedFetch.bare(LinkBuilder.legacyApi(`/document/${documentId}/property-folder`), {
      method: 'put',
      signal,
      body: JSON.stringify({
        propertyFolderId,
        propertyFormId
      })
    });
  }

  static async updateSubscriptionFolderStatus({
    documentId,
    signal,
    status
  }: {
    documentId: string | number,
    status: FormSigningState,
    signal?: AbortSignal
  }) {
    return WrappedFetch.bare(LinkBuilder.legacyApi(`/document/${documentId}/property-folder`), {
      method: 'put',
      signal,
      body: JSON.stringify({
        status: formSigningStateToString(status)
      })
    });
  }

  static async cloneSubsciptionDocument({
    documentId,
    signal,
    name
  }: {
    documentId: string | number,
    signal?: AbortSignal
    name: string;
  }) {
    const body = new FormData();
    body.set('newname', name);
    body.set('DocumentID', String(documentId));

    return WrappedFetch.bare(LinkBuilder.ajaxRequest({ action: 'clonedocument' }), {
      method: 'post',
      signal,
      body
    });
  }
}

export interface GetEntitySettingsInfoResponse extends BaseAjaxResponse {
  urlLogo: string,
  urlPurchaserHeader: string,
  entityId: number,
  entities: {
    entityId: number,
    displayName: string,
    rla?: string,
    abnAcn?: string,
    managementId: number,
    allowContractors: boolean,
    state?: string,
    useNewPropertyTransactions: boolean,
    epfAgencyId?: number
  }[]
}

interface GetDocumentMetaResponse {
  name: string;
  documentId: number;
  entityId: number;
  formId: number;
  formFileName: string;
  formName: string;
  signatures: string[];
  signingPortal: {
    enabled: boolean;
    propertyId?: string;
    propertyFormId?: string;
  }
}
